<template>
    <BaseModal :is-visible="modalStore.activeModals['filters'] === true">
        <template #title>Filter op</template>

        <template #default>
            <AgendaTypeFilter v-if="modalData.showAgendaTypeFilter" />
            <ArticleTypeFilter v-if="modalData.showArticleFilter" />
            <DossierFilter v-if="modalData.showDossierFilter" />
        </template>

        <template #dialog>
            <div class="filter-modal__actions">
                <BaseButton
                    size="medium"
                    class="base-button--transparent-dark"
                    @click="dismissModal"
                >
                    Annuleer
                </BaseButton>
                <BaseButton
                    size="medium"
                    @click="dismissModal"
                >
                    Toon resultaten
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
const modalStore = useModalStore();

const modalData = computed(() => {
    return modalStore.data.filters;
});

const dismissModal = () => {
    modalStore.deactivateAllModals();
};
</script>

<style lang="less">
.filter-modal__actions {
    margin-top: -1rem;
    padding: 0 2.5rem 1.5rem 2.5rem;
    text-align: right;

    button + button {
        margin-left: 1rem;
    }
}
</style>
